import React from 'react';
import Theme from '../components/PageCommon/Theming/ThemeProvider';
import PageContainer from '../components/PageCommon/PageContainer/PageContainer';
import PageMeta from '../components/PageCommon/PageMeta/PageMeta';
import Footer from '../components/PageCommon/Footer/Footer';
import MainContainer from '../components/PageCommon/MainContainer/MainContainer';
import NavBar from '../components/PageCommon/NavBar/NavBar';
import LatestPostsPageLayout from '../components/PageLayouts/Latest/LatestPostsPageLayout';
import HeroCard from '../components/Cards/HeroCard/HeroCard';
import { Grid } from '@material-ui/core';

import useLatestRecipePosts from '../hooks/useLatestRecipePosts';
import useLatestThoughtsPosts from '../hooks/useLatestThoughtsPosts';
import useLatestHealthPosts from '../hooks/useLatestHealthPosts';
import useSiteMetadata from '../hooks/useSiteMetadata';

import useAboutResponsiveImage from '../hooks/useAboutResponsiveImage';

const heroTitle = 'SVEIKI, ESMU BAIBA!';
const heroText = `Te dalos pārdomās, pieredzē, iespaidos un atklāsmēs par mani interesējošām tēmām, tādām kā veselība, ģimene, karjera, ceļojumi, un kā to visu veiksmīgi apvienot.
Pati esmu precējusies 3 bērnu mamma, esmu bijusi neliela uzņēmuma vadītāja, izmēģinājusi roku pati savu projektu īstenošanā, daudz laika veltu sevis izzināšanai un pilnveidošanai.
Īpašu uzmanību veltu tieši tēmām par veselīgu dzīvesveidu un uzturu.`;
const heroSubTitle = 'Laipni lūdzu manā bloga lapā!';

const IndexPage = () => {
    const latestThoughtsPosts = useLatestThoughtsPosts();
    const latestRecipePosts = useLatestRecipePosts();
    const latestHealthPosts = useLatestHealthPosts();

    const { siteUrl, categoryMap } = useSiteMetadata();
    const pageTitle = 'Latest posts';
    const url = siteUrl;
    const pageDescription = 'Latest posts from all categories';

    const fluid = useAboutResponsiveImage(2);

    return (
        <Theme>
            <PageMeta title={pageTitle} url={url} pageDescription={pageDescription} />
            <PageContainer>
                <NavBar page="root" />
                <MainContainer>
                    <Grid container spacing={1} style={{ marginBottom: '0.3rem' }}>
                        <Grid item style={{ width: '100%' }}>
                            <HeroCard
                                img={fluid}
                                title={heroTitle}
                                text={heroText}
                                subTitle={heroSubTitle}
                                split={false}
                            />
                        </Grid>
                    </Grid>
                    <LatestPostsPageLayout
                        postsData={[latestHealthPosts, latestRecipePosts, latestThoughtsPosts]}
                        categoryMap={categoryMap}
                    />
                </MainContainer>
                <Footer />
            </PageContainer>
        </Theme>
    );
};

export default IndexPage;
