import React from 'react';
import { Grid } from '@material-ui/core';
import SmallCard from '../../Cards/SmallCard/SmallCard';
import HeroCard from '../../Cards/HeroCard/HeroCard';
import PageTitleCard from '../../Cards/PageTitleCard/PageTitleCard';

const Layout = ({ postsData = false, categoryMap }) => {
    let parsedPosts = [];

    if (postsData) {
        parsedPosts = postsData.map((el) => ({
            hero: {
                img: el[0].lgFluid,
                title: el[0].title,
                text: el[0].excerpt_long,
                date: el[0].date,
                split: true,
                linkText: 'Lasīt tālāk...',
                linkHref: el[0].slug,
                themes: el[0].themes,
            },
            category: categoryMap[el[0].category],
            url: '/' + el[0].category,
            others: el.slice(1, 4).map((el) => ({
                img: el.smFluid,
                title: el.title,
                text: el.excerpt_short,
                date: el.date,
                linkText: 'Lasīt tālāk...',
                linkHref: el.slug,
                themes: el.themes,
            })),
        }));
    } else {
        console.log('LatestPostsPageLayout: no postsData received!');
    }

    return parsedPosts.map((el, idx) => {
        const TitleCard = () => (
            <Grid item xs={12} style={{ width: '100%' }}>
                <PageTitleCard label="JAUNĀKAIS NO: " linkText={el.category} href={el.url} />
            </Grid>
        );

        const HeroGrid = () => (
            <Grid item xs={12} style={{ width: '100%' }}>
                <HeroCard
                    title={el.hero.title}
                    text={el.hero.text}
                    date={el.hero.date}
                    img={el.hero.img}
                    linkText={el.hero.linkText}
                    linkHref={el.hero.linkHref}
                    themes={el.hero.themes}
                    split={el.hero.split}
                />
            </Grid>
        );
        const OtherGrid = () =>
            el.others.map((other_el, idx) => (
                <Grid item xs={12} sm={12} md={4} lg={4} key={idx} style={{ width: '100%' }}>
                    <SmallCard
                        title={other_el.title}
                        date={other_el.date}
                        img={other_el.img}
                        text={other_el.text}
                        linkText={other_el.linkText}
                        linkHref={other_el.linkHref}
                        themes={other_el.themes}
                    />
                </Grid>
            ));

        return (
            <Grid container spacing={1} style={{ marginBottom: '0.3rem' }} key={idx}>
                <TitleCard />
                <HeroGrid />
                <OtherGrid />
            </Grid>
        );
    });
};

export default Layout;
